(function () {
	'use strict';
	angular
		.module('app')
		.controller('PasswordCtrl', [
			'auth',
			'$scope',
			'$location',
			'$timeout',
			'manageUser',
			'manageSettings',
			'daybackIO',
			PasswordCtrl,
		]);

	function PasswordCtrl(
		auth,
		$scope,
		$location,
		$timeout,
		manageUser,
		manageSettings,
		daybackIO
	) {
		$scope.user = {};

		//auth is passed in from route resolve
		// $scope.user.username = auth.password ? auth.password.email : null;
		$scope.user.isTemporaryPassword = auth.password
			? auth.signIn.isTemporaryPassword
			: false;
		$scope.user.temporaryPassword = auth.signIn
			? auth.signIn.password
			: null;
		$scope.user.passwordOld = auth.signIn ? auth.signIn.password : null;
		$scope.changePassword = changePassword;

		manageSettings.getUserSettings(setUser);
		function setUser(result) {
			var user = daybackIO.getUser();
			$scope.user.username = user.email;
		}

		function changePassword(email, passwordOld, password, passwordConfirm) {
			if (!password) {
				$scope.user.message = 'Password is required';
			} else if (password.length < 6) {
				$scope.user.message =
					'Password must be at least 6 characters long';
			} else if (password !== passwordConfirm) {
				$scope.user.message = "Password confirmation doesn't match";
			} else {
				$scope.user.loading = true;
				manageUser.changePassword(
					email,
					passwordOld,
					password,
					onChangePassword
				);
			}
			function onChangePassword(result) {
				$timeout(function () {
					if (result.success) {
						$location.path('/');
					} else {
						$scope.user.loading = false;
						$scope.user.message = result.message;
					}
				}, 0);
			}
		}
	}
})();
